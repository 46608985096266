import React, { useState } from 'react'
import NotLoggedIn from './MenuSubPages/NotLoggedIn';
import LoggedIn from './MenuSubPages/LoggedIn';
function Menu() {
    const [logStaus, setLogStatus] = useState(1);
    return ( 
        <div id='menu' className='absolute top-0 left-1/2 w-3/4 h-screen items-center translate-x-[-50%] text-wrap'>
            <h1 className='text-4xl sm:text-5xl text-center pt-5 pb-10 sm:pb-20 text-slate-200'>Cybersecurity AEH Platform</h1>
            {logStaus === 0 &&
                <NotLoggedIn />
            }
            {logStaus === 1 &&
                <LoggedIn />
            }
        </div>
     );
}

export default Menu;