import React from 'react'
import { FaDiscord, FaNoteSticky } from "react-icons/fa6";
import { GoFileSubmodule } from "react-icons/go";
import { IoLogOut } from "react-icons/io5";

function LoggedIn() {
    return (
        <div className='grid sm:grid-cols-[repeat(2,300px)] translate-x-[-50px] m-auto p-auto gap-4 align-center justify-center'>

            <div className='w-48 h-10 rounded-lg sm:w-64 sm:h-16 text-slate-300 text-center justify-center bg-teal-700/75 
                items-center justify-center sm:rounded-xl shadow-xl translate-x-1/4
                transition ease-in-out hover:bg-teal-600 duration-150 cursor-pointer select-none row-span-1' onClick={
                    () => { window.open("https://drive.google.com/drive/folders/1zoAzxbSoDGyQbAqtFLIXWZbosdcMm5fW?usp=drive_link", "_blank")
                    }
                }>
                <div className='absolute items-center flex gap-2 sm:gap-4 text-xl sm:text-4xl left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%]'><GoFileSubmodule /><h3> Files</h3></div>
            </div>

            <div className='w-48 h-10 rounded-lg sm:w-64 sm:h-16 text-slate-300 text-center justify-center bg-orange-700/75 
                items-center justify-center sm:rounded-xl shadow-xl translate-x-1/4
                transition ease-in-out hover:bg-orange-600 duration-150 cursor-pointer select-none row-span-1' onClick={
                    () => {
                        alert("SOON.")       
                    }
                }>
                <div className='absolute items-center flex gap-2 sm:gap-4 text-xl sm:text-4xl left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%]'><FaNoteSticky /><h3> Tests</h3></div>
            </div>

            <div className='w-48 h-10 rounded-lg sm:w-64 sm:h-16 text-slate-300 text-center justify-center bg-blue-700/75 
                items-center justify-center sm:rounded-xl shadow-xl translate-x-1/4
                transition ease-in-out hover:bg-blue-600 duration-150 cursor-pointer select-none row-span-2' onClick={
                    () => { window.open("https://discord.gg/bfjxHszQMP", "_blank")}
                }>
                <div className='absolute items-center flex gap-2 sm:gap-4 text-xl sm:text-4xl left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%]'><FaDiscord/><h3> Discord</h3></div>
            </div>
            

            <div className='w-48 h-10 rounded-lg sm:w-64 sm:h-16 text-slate-300 text-center justify-center bg-red-700/75 
                items-center justify-center sm:rounded-xl shadow-xl translate-x-1/4
                transition ease-in-out hover:bg-red-600 duration-150 cursor-pointer select-none row-span-2' onClick={
                    () => {
                        alert("SOON.")       
                    }}>
                <div className='absolute items-center flex gap-2 sm:gap-4 text-xl sm:text-4xl left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%]'><IoLogOut /><h3> Logout</h3></div>
            </div>
        </div>
     );
}

export default LoggedIn;