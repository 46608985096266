import React from 'react'
function NotLoggedIn() {
    return ( 
        <div id='login' className='relative w-32 h-8 rounded-lg sm:w-64 sm:h-16 text-slate-300 text-center justify bg-emerald-700/75 
                items-center justify-center sm:rounded-xl w-3/4 left-1/2 translate-x-[-50%] shadow-xl
                transition ease-in-out hover:bg-emerald-600 duration-150 cursor-pointer select-none'>
            <h3 className='absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%] text-2xl sm:text-4xl'>Login.</h3>
        </div>
     );
}

export default NotLoggedIn;