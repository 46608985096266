import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import React, {lazy} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Menu from "./subpages/Menu";


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <>
  <Router>
    <Routes>
      <Route path="/" element={<Menu />} />
    </Routes>
  </Router>
  </>
);
